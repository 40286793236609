/* CSS */
import "@css/external.scss";

// Static image imports
import '@img/bgimageplaceholder.png'
import '@img/videos/home-video.mp4'
import '@img/videos/home-video-crop.mp4'
import '@img/videos/home-video-crop.webm'
import '@img/interface/home-promo-tour.png'
import '@img/interface/tour-tablet.png'
import '@img/videos/tour-competencies.mp4'
import '@img/videos/tour-favorites.mp4'
import '@img/videos/tour-tailored.mp4'
import '@img/videos/wave.mp4'
import '@img/misc/favicon.ico'
import '@img/misc/apple-touch-icon.png'

// Necessary function for Vite HMR
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR")
    });
}




// Let the document know when the mouse is being used
document.body.addEventListener('mousedown', function() {
    document.body.classList.add('using-mouse');
});

// Re-enable focus styling when Tab is pressed
document.body.addEventListener('keydown', function(event) {
    if (event.key === 'Tab') {
        document.body.classList.remove('using-mouse');
    }
});




//console.log("External javascript initialized");

